section#intro {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../../../public/images/home_cover.webp);
  height: calc(100vh + 10px);
  margin-top: -10px;
  width: 100%;
}
section#intro svg {
  width: 100%;
  height: 100%;
}
section#intro > div.container {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: absolute;
  width: max-content;
  transform: translate(-50%, -50%);
  top: 20%;
  left: 15%;
}
section#intro > div.container > h1,
section#intro > div.container > p {
  transition: color 300ms ease;
  -webkit-transition: color 300ms ease;
  color: rgb(213, 214, 215);
}
section#intro > div.container > p {
  text-align: right;
}

section#skills {
  min-height: 77vh;
}
section#skills h3 {
  margin-left: 2rem;
}

@media (max-width: 1600px) and (min-width: 1350px) {
  section#intro > div.container {
    top: 15%;
  }
}
@media (max-width: 1450px) {
  section#intro svg {
    width: 110%;
  }
}
@media (max-width: 1350px) and (min-width: 1070px) {
  section#intro > div.container {
    top: 15%;
    left: 20%;
  }
}
@media (max-width: 1170px) {
  section#intro svg {
    width: 140%;
    margin-left: -20rem;
  }
}
@media (max-width: 1070px) and (min-width: 650px) {
  section#intro > div.container {
    top: 10%;
    left: 30%;
  }
}
@media (max-width: 825px) {
  section#intro svg {
    margin-left: -15rem;
  }
}
@media (max-width: 650px) {
  section#intro svg {
    display: none;
  }
  section#intro > div.container {
    width: 25rem;
    left: 50%;
  }
}
@media (max-width: 430px) {
  section#intro > div.container {
    width: max-content;
  }
  section#intro > div.container > h1 {
    width: max-content;
  }
  section#intro > div.container > p {
    font-size: 1.1rem;
  }
}
@media (max-width: 255px) {
  section#intro > div.container {
    width: 100%;
  }
  section#intro > div.container > p {
    font-size: 1rem;
  }
}
/* Global modal style */
.modal {
  --bs-modal-box-shadow: 0rem .5rem 1rem rgba(0, 0, 0, .94);
  --bs-modal-border-color: rgb(255, 255, 255);
  --bs-modal-border-width: 2px;
  height: 100dvh;
}
.modal.show {
  display: block;
}
.modal .modal-content {
  background-color: rgb(11, 12, 13);
  box-shadow: var(--bs-modal-box-shadow);
  padding: .6rem;
}
.modal .modal-content h3 {
  margin-bottom: 1.2rem;
}
.modal .modal-content > .btn-wrapper > button:not(.btn-responsive) {
  color: rgb(185, 185, 185);
}
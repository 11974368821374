header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.26);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  font-family: Arial;
  position: fixed;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8.7px);
  -webkit-backdrop-filter: blur(8.7px);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
}

header > .wrapper {
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
}

header > .wrapper > div:not(:last-child) {
  padding-right: 1rem;
}

header > .wrapper.scrolled section.branding {
  border-right-color: rgb(255, 255, 255);
}
header > .wrapper section.branding h1 {
  transition: color 300ms ease;
  -webkit-transition: color 300ms ease;
}
header > .wrapper.scrolled section.branding h1 {
  color: rgb(6, 6, 7);
}

@media (prefers-color-scheme: light) {
  header > .wrapper.scrolled section.branding {
    border-right-color: rgb(0, 0, 0);
  }
  header > .wrapper.scrolled section.branding h1 {
    color: rgb(255, 255, 255);
  }
}

/* Desktop View */
@media (min-width: 600px) {
  /* Header - Branding */
  header > .wrapper section.branding {
    transition: border 300ms ease;
    -webkit-transition: border 300ms ease;
    border-right-style: solid;
    border-right-color: rgb(0, 0, 0);
    border-right-width: 1px;
    padding-right: 1rem;
    margin-left: 1.25rem;
  }
  header > .wrapper section.branding h1 {
    transform: translateY(10%);
  }

  /* Header - Navigation menu */
  header > .wrapper section.navigation-menu {
    transform: translateY(30%);
    margin-left: 2rem;
  }
  header > .wrapper section.navigation-menu .hamburger {
    display: none;
  }
  header > .wrapper section.navigation-menu ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    gap: 1rem;
  }
  header > .wrapper section.navigation-menu ul > li {
    list-style: none;
    text-decoration: none;
  }
  header > .wrapper section.navigation-menu ul > li:not(:first-child) {
    transition: border 300ms ease;
    -webkit-transition: border 300ms ease;
    border-left-style: solid;
    border-left-color: rgb(0, 0, 0);
    border-left-width: 1px;
    padding-left: 1rem;
  }
  header > .wrapper.scrolled section.navigation-menu ul > li:not(:first-child) {
    border-left-color: rgb(255, 255, 255);
  }
  header > .wrapper section.navigation-menu ul > li > a {
    transition: color 300ms ease;
    -webkit-transition: color 300ms ease;
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
  header > .wrapper.scrolled section.navigation-menu ul > li > a {
    color: rgb(0, 0, 0);
  }
  header > .wrapper section.navigation-menu ul > li > a > svg {
    display: none;
  }
  @media (prefers-color-scheme: light) {
    header > .wrapper.scrolled section.navigation-menu ul > li:not(:first-child) {
      border-left-color: rgb(0, 0, 0);
    }
    header > .wrapper section.navigation-menu ul > li > a {
      color: rgb(0, 0, 0);
    }
    header > .wrapper.scrolled section.navigation-menu ul > li > a {
      color: rgb(255, 255, 255);
    }
  }

  /* Header - Action Row */
  header > .wrapper section.action-row {
    position: absolute;
    right: 2.5rem;
    top: 1.5rem;
  }
  header > .wrapper section.action-row a {
    font-size: 1.75rem;
    padding: 0.25rem;
    margin: 0.5rem;
  }
  header > .wrapper section.action-row a i,
  header > .wrapper section.action-row a svg {
    transition: color 300ms ease;
    -webkit-transition: color 300ms ease;
    color: rgb(255, 255, 255);
  }
  header > .wrapper.scrolled section.action-row a i,
  header > .wrapper.scrolled section.action-row a svg {
    color: rgb(0, 0, 0);
  }
  header > .wrapper section.action-row a i:hover,
  header > .wrapper section.action-row a i:focus,
  header > .wrapper section.action-row a i:active,
  header > .wrapper section.action-row a svg:hover,
  header > .wrapper section.action-row a svg:focus,
  header > .wrapper section.action-row a svg:active {
    color: rgb(200, 200, 200);
  }
  header > .wrapper.scrolled section.action-row a i:hover,
  header > .wrapper.scrolled section.action-row a i:focus,
  header > .wrapper.scrolled section.action-row a i:active,
  header > .wrapper.scrolled section.action-row a svg:hover,
  header > .wrapper.scrolled section.action-row a svg:focus,
  header > .wrapper.scrolled section.action-row a svg:active {
    color: rgb(58, 59, 40);
  }
  @media (prefers-color-scheme: light) {
    header > .wrapper section.action-row a i,
    header > .wrapper section.action-row a svg {
      color: rgb(0, 0, 0);
    }
    header > .wrapper.scrolled section.action-row a i,
    header > .wrapper.scrolled section.action-row a svg {
      color: rgb(255, 255, 255);
    }
    header > .wrapper section.action-row a i:hover,
    header > .wrapper section.action-row a i:focus,
    header > .wrapper section.action-row a i:active,
    header > .wrapper section.action-row a svg:hover,
    header > .wrapper section.action-row a svg:focus,
    header > .wrapper section.action-row a svg:active {
      color: rgb(58, 59, 40);
    }
    header > .wrapper.scrolled section.action-row a i:hover,
    header > .wrapper.scrolled section.action-row a i:focus,
    header > .wrapper.scrolled section.action-row a i:active,
    header > .wrapper.scrolled section.action-row a svg:hover,
    header > .wrapper.scrolled section.action-row a svg:focus,
    header > .wrapper.scrolled section.action-row a svg:active {
      color: rgb(200, 200, 200);
    }
  }
}

/* Mobile View */
@media (max-width: 600px) {
  /* Header - Branding */
  header > .wrapper section.branding {
    transition: border 300ms ease;
    -webkit-transition: border 300ms ease;
    border-right-style: solid;
    border-right-color: rgb(0, 0, 0);
    border-right-width: 1px;
    padding-right: 1rem;
    margin-left: 1.25rem;
    z-index: 10;
  }
  header > .wrapper section.branding h1 {
    transform: translateY(18%);
  }

  /* Header - Navigation Menu */ 
  header > .wrapper section.navigation-menu .hamburger {
    position: absolute;
    cursor: pointer;
    border: none;
    background: none;
    border-radius: 0.1em;
    right: 1rem;
    top: 1.5rem;
    z-index: 10;
  }
  header > .wrapper section.navigation-menu .hamburger > div {
    background-color: rgb(255, 255, 255);
    border-radius: 0.1rem;
    width: 1.875rem;
    height: 3px;
    margin: 5px;
  }
  header > .wrapper.scrolled section.navigation-menu .hamburger > div {
    background-color: rgb(6, 6, 7);
  }
  header > .wrapper section.navigation-menu ul {
    transition: all 1s ease-out;
    box-shadow:  41px 41px 82px #2e3036, -41px -41px 82px #3e4248;
    visibility: hidden;
    position: absolute;
    height: max-content;
    width: max-content;
    background-color: rgb(32, 34, 37);
    min-height: 7em;
    left: -105%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 40px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    z-index: 9;
  }
  header > .wrapper section.navigation-menu ul.open {
    visibility: visible;
    left: 0;
  }

  header > .wrapper section.navigation-menu ul > li {
    list-style: none;
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  header > .wrapper section.navigation-menu ul > li > a {
    color: rgb(255, 255, 255);
  }
  header > .wrapper section.navigation-menu ul > li > a > svg {
    margin-right: 1rem;
  }
  @media (prefers-color-scheme: light) {
    header > .wrapper section.navigation-menu ul {
      box-shadow:  41px 41px 82px #b5b6b7, -41px -41px 82px #f5f6f7;
      background-color: rgb(213, 214, 215);
    }
  }

  /* Header - Action Row */
  header > .wrapper section.action-row {
    transform: translateY(25%);
    z-index: 10;
  }
  header > .wrapper section.action-row a {
    font-size: 1.5rem;
    padding: 0.25rem;
    margin: 0.5rem;
  }
  header > .wrapper section.action-row a.disabled-link {
    pointer-events: none;
  }
  header > .wrapper section.action-row a i,
  header > .wrapper section.action-row a svg {
    transition: color 300ms ease;
    -webkit-transition: color 300ms ease;
    color: rgb(255, 255, 255);
  }
  header > .wrapper.scrolled section.action-row a i,
  header > .wrapper.scrolled section.action-row a svg {
    color: rgb(0, 0, 0);
  }
  header > .wrapper section.action-row a i:hover,
  header > .wrapper section.action-row a i:focus,
  header > .wrapper section.action-row a i:active,
  header > .wrapper section.action-row a svg:hover,
  header > .wrapper section.action-row a svg:focus,
  header > .wrapper section.action-row a svg:active {
    color: rgb(200, 200, 200);
  }
  header > .wrapper.scrolled section.action-row a i:hover,
  header > .wrapper.scrolled section.action-row a i:focus,
  header > .wrapper.scrolled section.action-row a i:hover,
  header > .wrapper.scrolled section.action-row a svg:hover,
  header > .wrapper.scrolled section.action-row a svg:focus,
  header > .wrapper.scrolled section.action-row a svg:active {
    color: rgb(58, 59, 40);
  }
  @media (prefers-color-scheme: light) {
    header > .wrapper section.action-row a i,
    header > .wrapper section.action-row a svg {
      color: rgb(0, 0, 0);
    }
    header > .wrapper.scrolled section.action-row a i,
    header > .wrapper.scrolled section.action-row a svg {
      color: rgb(255, 255, 255);
    }
    header > .wrapper section.action-row a i:hover,
    header > .wrapper section.action-row a i:focus,
    header > .wrapper section.action-row a i:active,
    header > .wrapper section.action-row a svg:hover,
    header > .wrapper section.action-row a svg:focus,
    header > .wrapper section.action-row a svg:active {
      color: rgb(58, 59, 40);
    }
    header > .wrapper.scrolled section.action-row a i:hover,
    header > .wrapper.scrolled section.action-row a i:focus,
    header > .wrapper.scrolled section.action-row a i:hover,
    header > .wrapper.scrolled section.action-row a svg:hover,
    header > .wrapper.scrolled section.action-row a svg:focus,
    header > .wrapper.scrolled section.action-row a svg:active {
      color: rgb(200, 200, 200);
    }
  }
}
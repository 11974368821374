/* Global Styles */
div.root-page > *:first-child {
  margin-top: 6rem;
}
div.root-page[data-page-name="home"] {
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;
}
div[data-page-name="projects"] h1 {
  margin-left: 1rem;
}
div.page[data-page=contributions] > div.projects-grid,
div.page[data-page=projects] > div.projects-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, 25rem);
  grid-template-rows: 26rem;
  grid-auto-rows: 2fr;
  padding: 2rem;
  gap: 3rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

/* Change gap between cards once cards start wrapping to a new line */
@media (max-width: 1360px) {
  div.page[data-page=contributions] > div.projects-grid,
  div.page[data-page=projects] > div.projects-grid {
    gap: 2rem;
  }
}
/* Mobile View */
@media (max-width: 895px) {
  div.page[data-page=contributions] > div.projects-grid,
  div.page[data-page=projects] > div.projects-grid {
    display: flex;
    align-items: center;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    padding: 2rem;
    gap: 3rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  /* Custom Scrollbar */
  div.page[data-page=contributions] > div.projects-grid::-webkit-scrollbar,
  div.page[data-page=projects] > div.projects-grid::-webkit-scrollbar {
    height: 10px;
  }
  div.page[data-page=contributions] > div.projects-grid::-webkit-scrollbar-track,
  div.page[data-page=projects] > div.projects-grid::-webkit-scrollbar-track {
    background-color: transparent;
  }
  div.page[data-page=contributions] > div.projects-grid::-webkit-scrollbar-thumb,
  div.page[data-page=projects] > div.projects-grid::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(170, 170, 170);
  }
  div.page[data-page=contributions] > div.projects-grid::-webkit-scrollbar-thumb:hover,
  div.page[data-page=projects] > div.projects-grid::-webkit-scrollbar-thumb:hover,
  div.page[data-page=contributions] > div.projects-grid::-webkit-scrollbar-thumb:focus,
  div.page[data-page=projects] > div.projects-grid::-webkit-scrollbar-thumb:focus,
  div.page[data-page=contributions] > div.projects-grid::-webkit-scrollbar-thumb:active,
  div.page[data-page=projects] > div.projects-grid::-webkit-scrollbar-thumb:active {
    background: rgb(113, 113, 113);
  }
}